<template>
  <div>
    <h3 class="text-center">在刊率对比</h3>
    <div style="width:290px;height:170px;" id="onlineContractReport"></div>
    <Table stripe size="small" :data="contactTypeData" :columns="contactTypeColumn"></Table>

    <h3 class="text-center p-t-20 p-b-5">在刊品牌清单</h3>
    <Table stripe size="small" :data="contractBrandData" :columns="contractBrandColumn"></Table>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { countOnlineContractAmountGb, countOnlineContractResourceRate } from '@/api/dw/datav'
import { toMoney } from '@/utils/wnumb_own'

export default {
  data () {
    return ({
      publisherId: this.$store.getters.token.userInfo.publisherId,
      companyId: this.$store.getters.token.userInfo.companyId,
      dataSourcePie: null,

      contactTypeColumn: [
        {
          title: '类型',
          width: 60,
          render: (h, params) => {
            return h('a',
              {
                style: {
                  color: this.chooseContractFeature === params.row.id ? '#ff9900' : '#2D8cF0'
                },
                on: {
                  click: () => {
                    this.loadeContractBrand(params.row.id)
                  }
                }
              }, params.row.name
            )
          }
        },
        {
          title: '签约额(元)',
          width: 120,
          render: (h, params) => {
            return h('span', toMoney(params.row.value))
          }
        },
        { title: '在刊品牌数', width: 100, align: 'center', key: 'number' }
      ],
      contactTypeData: [],

      contractBrandColumn: [
        { title: '品牌名称', key: 'name' },
        {
          title: '签约额(元)',
          width: 120,
          render: (h, params) => {
            return h('span', toMoney(params.row.value))
          }
        },
        {
          title: '占比',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.rate + '%')
          }
        }
      ],
      contractBrandData: [],
      chooseContractFeature: null
    })
  },
  mounted () {
    if (this.companyId === this.publisherId) {
      this.companyId = 0
    }

    this.loadContractType()
    this.loadeContractBrand(null)
    this.loadContractResourceRate()
  },
  methods: {
    loadContractType () {
      const queryModel = {
        publisherId: this.publisherId,
        companyId: this.companyId,
        contractFeature: this.chooseContractFeature,
        date: this.publishDate,
        gbType: 'type' // brand:品牌;type:合同类型
      }

      countOnlineContractAmountGb(queryModel).then(res => {
        this.contactTypeData = res
      })
    },
    loadeContractBrand (contractFeature) {
      if (this.chooseContractFeature === contractFeature) {
        this.chooseContractFeature = null
      } else {
        this.chooseContractFeature = contractFeature
      }

      const queryModel = {
        publisherId: this.publisherId,
        companyId: this.companyId,
        contractFeature: this.chooseContractFeature,
        date: this.publishDate,
        gbType: 'brand' // brand:品牌;type:合同类型
      }

      countOnlineContractAmountGb(queryModel).then(res => {
        this.contractBrandData = res
      })
    },
    loadContractResourceRate () {
      const queryModel = {
        publisherId: this.publisherId,
        companyId: this.companyId,
        date: this.publishDate
      }

      countOnlineContractResourceRate(queryModel).then(res => {
        const labels = []
        const ownValue = { value: [], name: '在刊率对比', title: [] }

        res.forEach(element => {
        // 添加标注信息
          labels.push({ name: element.name, max: 100 })
          ownValue.value.push(element.rate)
          ownValue.title.push(element.name)
        })

        this.initChart(labels, ownValue)
      })
    },
    initChart (labels, ownValue) {
      this.$nextTick(() => {
        // 防止页面被切换，元素被移除
        if (document.getElementById('onlineContractReport')) {
          this.dataSourcePie = echarts.init(document.getElementById('onlineContractReport'))
          const option = {
            color: ['#FA8B59', '#4285F4'],
            tooltip: {
              trigger: 'axis',
              position: ['40%', '40%']
            },
            radar: [
              {
                indicator: labels,
                radius: 70,
                axisName: {
                  fontSize: '10',
                  padding: [0, -6]
                }
              }
            ],
            grid: {
              top: '0%',
              left: '40%',
              right: '40%',
              bottom: '0%'
            },
            series: [
              {
                type: 'radar',
                tooltip: {
                  trigger: 'item'
                },
                areaStyle: {},
                data: [ownValue]
              }
            ]
          }

          this.dataSourcePie.setOption(option, true)
        }
      })
    }
  },
  computed: {
    publishDate () {
      return this.$store.state.statistic.publishDate
    }
  },
  watch: {
    publishDate () {
      if (this.dataSourcePie != null && this.dataSourcePie !== '' && this.dataSourcePie !== undefined) {
        this.dataSourcePie.dispose()
      }

      this.loadContractType()
      this.loadeContractBrand(null)
      this.loadContractResourceRate()
    }
  }

}
</script>
